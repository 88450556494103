(function () {
  'use strict';

  angular
    .module('login.login')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('login.login', {
        url: '',
        templateUrl: 'login/login/login.tpl.html',
        controller: 'LoginCtrl',
        controllerAs: 'vm',
        authentication: false
      });
  }
}());
